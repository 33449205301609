<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Starter page",
      items: [
        {
          text: "Utility",
          href: "/",
        },
        {
          text: "Starter page",
          active: true,
        },
      ],

      ismobile: false,
      activeNames: [],
      activeapp: [],
      showmodel: "1",
      userinfo: [],
      safeinfo: [],
      loading: true,
      block: false,
      networkcardlist: [],
      wifilist: [],
      lanport: "",
      laniptype: 0,
      wlanport: "",
      dnsinfo: [],
      wlandata: {
        ssid: "",
        password: "",
      },
      wlanloading: true,
      dnsloading: false,
      systemtypeloading: true,
      systemtypes: [
        {
          value: "0",
          label: this.$t("setting.system.default"),
        },
        {
          value: "1",
          label: this.$t("setting.system.counter"),
        },
        {
          value: "2",
          label: this.$t("setting.system.card"),
        },
      ],
      systemtype: 0,
      systeminfo: [],
      serialstatus: 0,
      supportserial: false,
      tcpport: "",
      tcploading: true,
      printerid: "",
      printerloading: true,
      ipv4reg:
        "/^((25[0-5]|2[0-4]d|((1d{2})|([1-9]?d))).){3}(25[0-5]|2[0-4]d|((1d{2})|([1-9]?d))|*)((/([012]d|3[012]|d))?)(,((25[0-5]|2[0-4]d|((1d{2})|([1-9]?d))).){3}(25[0-5]|2[0-4]d|((1d{2})|([1-9]?d))|*)((/([012]d|3[012]|d))?))*$/",
      ipv6reg:
        "/^s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]d|1dd|[1-9]?d)(.(25[0-5]|2[0-4]d|1dd|[1-9]?d)){3}))|:)))(%.+)?s*$/",
    };
  },
  mounted() {
    var that = this;
    if ("serial" in navigator) {
      that.supportserial = true
      if(localStorage.getItem("serial")){
        that.serialstatus = localStorage.getItem("serial");
      }
    }
    
    that.title = that.$t("menuitems.setting.system");
    that.items = [
      {
        text: that.$t("menuitems.setting.text"),
        href: "/",
      },
      {
        text: that.$t("menuitems.setting.system"),
        active: true,
      },
    ];

    that.userinfo = JSON.parse(localStorage.getItem("user"));
    if (that.userinfo.topuid >= 1) {
      that.block = true;
    }
    let flag = navigator.userAgent.match(
      /(phone|pod|iPhone|iPod|ios|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
    if (flag) {
      // that.ismobile = true;
    }
    that.getsysteminfo();
    that.gettcpinfo();
    that.getprinterid();
    that.websocket.ws.onmessage = function (res) {
      let wsdata = JSON.parse(res.data);

      if (wsdata.action === "netcard") {
        that.networkcardlist = wsdata.networkInterfaces;
      }
      if (wsdata.action === "dnsinfo") {
        that.dnsinfo = wsdata.dns;
      }
      if (wsdata.action === "scanwifi") {
        that.wlanloading = false;
        that.wifilist = wsdata.ssid;
        for (let i = 0; i < that.wifilist.length; i++) {
          if (that.wifilist[i].use === "*") {
            that.wlandata.ssid = that.wifilist[i].ssid;
          }
        }
      }
      if (wsdata.action === "connectwifi") {
        if (wsdata.msg) {
          that.getnetworkcardlist();
          that.$message({
            message: that.$t("setting.system.network.wlansuccess", {
              ssid: that.wlandata.ssid,
            }),
            type: "success",
          });
          that.wlanloading = false;
        }
      }
      if (wsdata.action === "disconnectwifi") {
        if (wsdata.msg) {
          that.getnetworkcardlist();
          that.$message({
            message: that.$t("setting.system.network.wlandisconnect", {
              ssid: that.wlandata.ssid,
            }),
            type: "success",
          });
          that.wlandata.ssid = "";
          that.wlandata.password = "";
          that.wlanloading = false;
        }
      }
      if (wsdata.action === "savedns") {
        that.$message({
          message: that.$t("setting.system.network.dnssuccess"),
          type: "success",
        });
      }
      if (wsdata.action === "changeipaddress") {
        if (wsdata.msg.code === 0) {
          that.$message({
            message: that.$t("setting.system.network.ipsuccess"),
            type: "success",
          });
        }
      }
    };
  },
  methods: {
    getsysteminfo() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "systeminfo",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.systemtypeloading = false;
          that.systeminfo = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getnetworkcardlist() {
      var that = this;
      let senddata = {
        action: "getnetcard",
      };
      that.websocket.ws.send(JSON.stringify(senddata));
    },
    getwifilist() {
      var that = this;
      let senddata = {
        action: "scanwifi",
      };
      that.websocket.ws.send(JSON.stringify(senddata));
    },
    getdnsinfo() {
      var that = this;
      let senddata = {
        action: "dnsinfo",
      };
      that.websocket.ws.send(JSON.stringify(senddata));
    },
    networkcardfilter() {
      var that = this;
      let array = [];
      array = this.networkcardlist;
      console.log(array);
      let newMapArr = array.every((item, i) => {
        console.log(i);
      });
    },
    ipcheck(type, value) {
      let check = false;
      if (type === "ipv4") {
        check = value;
      }
    },
    onSubmit() {
      var that = this;
      if (that.lanport.indexOf("eth") != -1) {
        let senddata = {
          action: "changeipaddress",
          data: that.networkcardlist[that.lanport][that.laniptype],
          card: that.lanport,
        };
        that.websocket.ws.send(JSON.stringify(senddata));
      }
      if (that.lanport.indexOf("wlan") != -1) {
        let senddata = {
          action: "changeipaddress",
          data: that.networkcardlist[that.lanport][that.laniptype],
          card: that.wlandata.ssid,
        };
        that.websocket.ws.send(JSON.stringify(senddata));
      }
    },
    dhcp() {
      var that = this;
      if (that.lanport.indexOf("eth") != -1) {
        let senddata = {
          action: "changeipdhcp",
          family: that.networkcardlist[that.lanport][that.laniptype].family,
          card: that.lanport,
        };
        that.websocket.ws.send(JSON.stringify(senddata));
      }
      if (that.lanport.indexOf("wlan") != -1) {
        let senddata = {
          action: "changeipdhcp",
          family: that.networkcardlist[that.lanport][that.laniptype].family,
          card: that.wlandata.ssid,
        };
        that.websocket.ws.send(JSON.stringify(senddata));
      }
    },
    savedns() {
      var that = this;
      let senddata = {
        action: "savedns",
        dns: that.dnsinfo,
      };
      that.websocket.ws.send(JSON.stringify(senddata));
    },
    savewlan() {
      var that = this;
      let senddata = {
        action: "connectwifi",
        ssid: that.wlandata.ssid,
        password: that.wlandata.password,
      };
      that.websocket.ws.send(JSON.stringify(senddata));
      that.wlanloading = true;
    },
    disconnectwlan() {
      var that = this;
      let senddata = {
        action: "disconnectwifi",
        ssid: that.wlandata.ssid,
      };
      that.websocket.ws.send(JSON.stringify(senddata));
      that.wlanloading = true;
    },
    changesystemmodel() {
      var that = this;
      that.systemtypeloading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "savesystemmodel",
            type: that.systeminfo.model,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.systemtypeloading = false;
          that.$message({
            message: that.$t("global.success"),
            type: "success",
          });
          that.getsysteminfo();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    reboottcp() {
      var that = this;
      let senddata = {
        action: "retcpserver",
      };
      that.websocket.ws.send(JSON.stringify(senddata));
      that.wlanloading = true;
    },
    gettcpinfo() {
      var that = this;
      that.tcploading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "gettcpport",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.tcploading = false;
          that.tcpport = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    savetcpport() {
      var that = this;
      that.tcploading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "changetcpport",
            port: that.tcpport,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.tcploading = false;
          that.$message({
            message: that.$t("global.success"),
            type: "success",
          });
          that.gettcpinfo();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    getprinterid() {
      var that = this;
      that.printerloading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getprinterid",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.printerloading = false;
          that.printerid = response.data.id;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    saveprinter() {
      var that = this;
      that.printerloading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "changeprinterid",
            id: that.printerid,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.printerloading = false;
          that.$message({
            message: that.$t("global.success"),
            type: "success",
          });
          that.getprinterid();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    testprinter() {
      var that = this;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "testprint",
            id: that.printerid,
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.$message({
            message: that.$t("global.success"),
            type: "success",
          });
          that.getprinterid();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async enable_serial() {
      var that = this;
      console.log(that.serialstatus)
      if (that.serialstatus==0) {
        that.serialstatus = 1;
        localStorage.setItem("serial", 1);
        that
          .$confirm("当前设置已经开启了串口读卡器，是否立刻连接", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(() => {
            that.openserial();
          })
          .catch(() => {});
      } else {
        that.serialstatus = 0;
        localStorage.setItem("serial", 0);
        setTimeout (() => {
          that.$message({
            message: "串口读卡器已经关闭",
            type: "success",
          });
          location.reload();
        }, 1000);
      }
    },
    async openserial() {
      var that = this;

      const port = await navigator.serial.requestPort();
      await port.open({ baudRate: 9600 });
      const textDecoder = new TextDecoderStream();
      const readableStreamClosed = port.readable.pipeTo(textDecoder.writable);
      const reader = textDecoder.readable.getReader();
      while (true) {
        const { value, done } = await reader.read();
        let strdata;
        if (done) {
          // 允许稍后关闭串口。
          reader.releaseLock();
          break;
        }
        // value 是一个 Uint8Array
        if (value.includes("=")) {
          let bitdata = value.split("=");
          let cardhex = bitdata[1].replace(/[\r\n]/g, "");
          cardhex = cardhex.replace(/\n/g, "");
          cardhex = cardhex.replace(/\r/g, "");

          let route = that.$route.path;
          if (route.includes("user/detail")) {
            that.bindcard(cardhex);
          } else {
            that.searchcarduid(cardhex);
          }
        }
      }
    },
    searchcarduid(hex) {
      var that = this;
      let userinfo = JSON.parse(localStorage.getItem("user"));
      that.$message(that.$t("global.loading.reading"));
      that.$axios
        .post(
          that.apiuri,
          {
            action: "finduser",
            hex: hex,
          },
          {
            headers: { Openid: userinfo.openid },
          }
        )
        .then(function (response) {
          let res = response.data;
          if (res.status == 200) {
            that.$message({
              message: that.$t("global.ws.finduser"),
              type: "success",
            });
            that.$router.push({
              path: "/user/detail",
              query: { id: res.data.id },
            });
          } else if (res.status == 202) {
            that.cardcheckout(hex);
          } else {
            let route = that.$route.path;
            if (route.includes("user/add")) {
            } else {
              that.$router.push({
                path: "/user/add",
                query: { id: hex },
              });
            }
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    cardcheckout(hex) {
      var that = this;
      let userinfo = JSON.parse(localStorage.getItem("user"));
      that.$message.error(that.$t("global.loading.reading"));
      that
        .$confirm(
          that.$t("device.order.checkouttip"),
          that.$t("global.delete.sureyouaction"),
          {
            confirmButtonText: that.$t("global.button.ok"),
            cancelButtonText: that.$t("global.button.cancel"),
            type: "warning",
          }
        )
        .then(() => {
          that.$axios
            .post(
              that.apiuri,
              {
                action: "checkout",
                hex: hex,
              },
              {
                headers: { Openid: userinfo.openid },
              }
            )
            .then(function (response) {
              that.$router.push({path: "/device/orders"});
              if (response.data.status == 200) {
                that.$message({
                  message: that.$t("global.success"),
                  type: "success",
                });
              } else if (response.data.status == 403) {
                that.$message({
                  message: that.$t("global.delete.auth"),
                  type: "warning",
                });
              } else {
                that.$message.error(that.$t("global.delete.500"));
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(() => {});
    },
    bindcard(hex) {
      var that = this;
      let userinfo = JSON.parse(localStorage.getItem("user"));
      that.$message.error(that.$t("global.loading.reading"));
      that
        .$confirm(
          that.$t("global.ws.userbindcard"),
          that.$t("global.delete.sureyouaction"),
          {
            confirmButtonText: that.$t("global.button.ok"),
            cancelButtonText: that.$t("global.button.cancel"),
            type: "warning",
          }
        )
        .then(() => {
          that.$axios
            .post(
              that.apiuri,
              {
                action: "bindnewcard",
                hex: hex,
                uid: that.$route.query,
              },
              {
                headers: { Openid: userinfo.openid },
              }
            )
            .then(function (response) {
              if (response.data.status == 200) {
                that.$message({
                  message: that.$t("global.ws.bindsuccess"),
                  type: "success",
                });
                that.$router.push({
                  path: "/user/list",
                });
              } else if (response.data.status == 403) {
                that.$message({
                  message: that.$t("global.delete.auth"),
                  type: "warning",
                });
              } else {
                that.$message.error(that.$t("global.delete.500"));
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(() => {});
    },
  },
  computed: {
    lancardlist() {
      var datas = this.networkcardlist;
      datas = datas.filter((item, idx) => {
        console.log(idx);
      });

      return datas;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" v-if="!block">
      <div class="col-6">
        <div class="card">
          <div class="card-body" v-loading="systemtypeloading">
            <h5 class="card-title">{{ $t("setting.system.softtype") }}</h5>
            <el-form ref="form" label-width="80px">
              <el-form-item :label="$t('setting.system.softtype')">
                <el-select
                  v-model="systeminfo.model"
                  :placeholder="$t('global.text.select')"
                >
                  <el-option
                    v-for="item in systemtypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item>
                <el-button type="primary" @click="changesystemmodel">{{
                  $t("setting.system.network.save")
                }}</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>

      <div class="col-6">
        <div class="card">
          <div class="card-body" v-loading="tcploading">
            <h5 class="card-title">{{ $t("setting.system.tcpserver") }}</h5>
            <el-form ref="form" label-width="80px">
              <el-form-item :label="$t('setting.system.tcpport')">
                <el-input
                  type="int"
                  min="4001"
                  max="39999"
                  v-model="tcpport"
                  :placeholder="$t('setting.system.tcpport')"
                ></el-input>
              </el-form-item>

              <el-form-item>
                <el-button type="primary" @click="savetcpport">{{
                  $t("setting.system.network.save")
                }}</el-button>
                <el-button @click="reboottcp">{{
                  $t("setting.system.restart")
                }}</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="card">
          <div class="card-body" v-loading="printerloading">
            <h5 class="card-title">{{ $t("menuitems.devices.printer") }}</h5>
            <el-form ref="form" label-width="80px">
              <el-form-item :label="$t('setting.system.tcpport')">
                <el-input
                  type="int"
                  min="10000000"
                  max="99999999"
                  v-model="printerid"
                  :placeholder="$t('setting.system.deviceid')"
                ></el-input>
              </el-form-item>

              <el-form-item>
                <el-button type="primary" @click="saveprinter">{{
                  $t("setting.system.network.save")
                }}</el-button>
                <el-button v-if="printerid" @click="testprinter">{{
                  $t("setting.system.testprinter")
                }}</el-button>
                <el-button v-else disabled>{{
                  $t("setting.system.testprinter")
                }}</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div class="col-6" v-if="supportserial">
        <div class="card">
          <div class="card-body" v-loading="printerloading">
            <h5 class="card-title">
              {{ $t("menuitems.clouds.nfcreader.text") }}
            </h5>
            <el-form ref="form" label-width="80px">
              <el-form-item :label="$t('setting.system.action')">
                <el-button
                  type="primary"
                  @click="enable_serial"
                  v-if="serialstatus==0"
                  >{{ $t("setting.system.enableserial") }}</el-button
                >
                <el-button
                  type="primary"
                  @click="enable_serial"
                  v-if="serialstatus==1"
                  >{{ $t("setting.system.disableserial") }}</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <el-result
        icon="error"
        :title="$t('global.error')"
        :subTitle="$t('global.text.nopermission')"
      >
        <template slot="extra">
          <el-button type="primary" size="medium">返回</el-button>
        </template>
      </el-result>
    </div>
  </Layout>
</template>